.listing-detail-container {
  margin:1vw;
}

.listing-popup .right-column {height:100%!important;}

.listing-detail-container #root > div > div.listing-detail-container > div > div.main-content > div.left-column > div.custom-listing-container > h2 {
  display: none !important;
}

.listing-detail-container p {
  font-family: 'Inter';
  line-height:28px;
  text-align:left;
}

#root > div > div.listing-detail-container > div > div.login-register-overlay {display:none!important;}

.listing-detail-content {
  margin-left: 3vw;
  margin-right: 3vw;
  padding: 20px;
  background-color: white;
  padding-bottom: 50px !important;
}

.view-button {justify-content:start;display:flex;}



.view-images-button {padding: 10px;
  border-radius: 0px;
  border-width: 1px !important;
  background-color: #F3FAFD;
  border: solid;
  font-family:'Inter';
  font-size:15px;
  cursor:pointer;
}

@media (max-width:768px) {.view-images-button {padding: 10px;
  border-radius: 0px;
  margin-bottom:15px;
  border-width: 1px !important;
  background-color: #F3FAFD;
  border: solid;
  font-family:'Inter';
  font-size:15px;
  cursor:pointer;
}}

#root > div > div > div.listing-popup > div.popup-content > div > div > div > div.view-button > button > svg {vertical-align:middle;color:black;}

.right-column img {
  width: 300px !important;
}

.popup-content .slick-slide img {width:100%;height:auto;}

.slider-wrapper {max-width:none;}

.popup-content img {max-width:100%;}

/* Slider color change */
.slick-prev, .slick-next {
  color: #9CDCF8 !important;
}

/* Hide mobile-gallery section on larger screens */
.desktop-gallery {
  display: block;
}

.mobile-gallery {
  display: none;
}

/* Show appropriate section based on device */
@media (max-width: 768px) {
  .listing-detail-container,
  .listing-detail-content {
    padding: 0px;
  }

  .listing-detail-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .bottom-bar {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  }

  .main-content {
    flex-direction: column;
  }

  .left-column,
  .right-column {
    width: 100%;
  }

  .grid-container {
    display: none;
  }

  .mobile-gallery {
    display: block;
  }

  .desktop-gallery {
    display: none;
  }

  .hero-image {
    position: relative;
    width: 100%;
  }

  .hero-image img {
    width: 100%;
  }

  .image-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  .image-nav.prev {
    left: 10px;
  }

  .image-nav.next {
    right: 10px;
  }

  .details {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .info-item {
    margin-bottom: 10px;
  }

  .with-words {
    display: none;
  }

  .without-words {
    display: inline;
  }

  .icon {
    margin-right: 5px;
  }

  /* Dropdown styling for mobile */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    color: black;
  }

  /* Price range input adjustments for mobile */
  .price-range-input {
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
  }

  .info-table-container {
    flex-direction: column;
  }

  .mortgage-calculator-container {
    flex-direction: column;
  }

  .mortgage-pie-chart {
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) {
  .bottom-bar {
    display: none !important;
  }

  .listing-detail-container .listing-detail-content {
    margin: 0;
    padding: 0;
  }

  

  .right-column {
    margin: 0 !important;
    padding: 0 !important;
  }

  .property-info .date, 
  .property-info .price {
    font-size: 18px;
  }

  .address-heading {
    font-size: 1.5em;
  }

  .details .info-item {
    font-size: 20px;
  }

  .with-words {
    display: inline;
  }

  .without-words {
    display: none;
  }

  .small-image {
    display: block;
  }

  .mobile-gallery {
    display: none;
  }

  .desktop-gallery {
    display: block;
  }

  .image-nav {
    display: none;
  }

  .info-table-container {
    display: flex;
  }
}

.feature {
  background-color: #efefef;
  border-radius: 0px;
  padding: 7px;
  text-align: center;
  font-size: 18px;
  font-weight: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 24px;
}

.grid-container {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 2.5% 1%;
  width: 100%;
  margin-top: 1vw;
}

.image-wrapper {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.hero-image {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.small-image {
  grid-row: span 1;
  padding-top: 33.33%; /* 3:1 aspect ratio for small images */
}

.image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(124, 217, 240, 0.90);
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Ensure overlay doesn't block click events */
  z-index: 1; /* Ensure overlay is above the image but below the modal */
}

.image-wrapper:hover .image-overlay {
  opacity: 0.5;
}

.grid-template-features {
  display: grid;
  grid-template-columns: 50% 49%;
  gap: 20px;
  margin-top: 20px;
}

.property-action-box {
  background-color:white;
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  margin-top:20px;
}

.property-action-box p {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 200;
  margin-top:0px;
}

.property-action-button {
  background-color: #9cdcf8;
  border: 1px solid black;
  border-radius: 0px;
  padding: 10px 20px;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: 200;
  margin-right:10px;
}

.right-column {
  text-align: left;
  padding: 20px !important;
  background-color: #e6e6e6;
  padding: 20px;
  border-radius: 5px;
  position: -webkit-sticky;
  position: sticky !important;
  display: block;
  top: 0px;
  padding-top: 0px; /* Ensure content starts at the top */
}

.listing-detail-container .checkbox-container label {
  color: black !important;
}

.contact-form-box {
  padding: 20px;
  border: 1px solid #ccc;
  font-family: 'Inter', sans-serif;
  background-color:white;
}

.contact-form-box input,
.contact-form-box textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  background-color:#efefef;
}

.contact-form-box .half-width {
  width: 46%;
  display: inline-block;
}



.contact-form-box input[type='submit'] {
  background-color: #9cdcf8;
  border: 1px solid black;
  border-radius: 0px;
  padding: 10px 20px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: black;
  cursor: pointer;
  width: auto;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

.contact-form-box textarea {
  height: 100px;
}

.property-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.property-info-separator {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}

.property-info .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.property-info .details .info-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
}

.property-info .details .info-item img {
  background-color: #9cdcf8;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.property-info .price {
  font-size: 24px;
  font-weight: bold;
  color: #3183ce;
  font-family: 'Inter', sans-serif;
}

.property-info .date {
  font-size: 24px;
  font-weight: bold;
  color: black;
  font-family: 'Inter', sans-serif;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.details .info-item {
  font-size: 24px;
  font-weight: bold;
}

.text-container {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
}

.description {
  text-align: left;
  margin-bottom: 50px;
  font-size: 18px;
}

.section-heading {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  font-family: 'Inter', sans-serif;
}

.area-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.area-date .area,
.area-date .date {
  font-size: 18px;
}

.icon-text-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-family: 'Inter';
  font-size: 23px;
}

.icon-text-container img {
  margin-right: 10px;
}

.icon {
  width: 50px;
}

.bottom-bar {
  display: flex;
  justify-content: space-between;
}

.action-button {
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  width: 45%;
  max-width: 200px;
  margin: 0 10px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

.Inter-font {
  font-family: 'Inter', sans-serif;
}

.listing-detail-container .left-column {
  padding: 0px;
}

.address-container {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.address-heading {
  font-size: 2em; /* Adjust as needed */
  font-weight: bold;
  color: #333; /* Adjust the color as needed */
  font-family: 'Inter';
}

.listing-detail-container .left-column {
  display: block !important;
}

.brokerage-info {
  font-size: 13px;
  color: #666; /* Adjust the color as necessary */
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif; /* Ensure it matches the rest of your site's styling */
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.info-table th, .info-table td {
  border: 0px solid #ccc;
  padding: 12px;
  text-align: left;
  font-family: 'Inter';
  font-size: 20px;
}

@media (max-width:1024px) {
  .info-table th, .info-table td {
    border: 0px solid #ccc;
    padding: 12px;
    text-align: left;
    font-family: 'Inter'!important;
    font-size: 16px!important;
  }

  .listing-detail-container p {
    font-size:16px;
  }

  .info-table th {font-size:17px!important;}
}

.info-table th {
  font-weight: bold;
  background-color: #f9f9f9;
  font-family: 'Inter';
  font-size: 20px;
}

.contact-form {margin-top:0px;}


.info-table tr:nth-child(even) td {
  background-color: #f3fafd;
}

.info-table td {
  background-color: #fff;
}

.link-buttons {
  text-align: left;
}

.info-table-container {
  display: flex;
}

#root > div > div.listing-detail-container > div > div.main-content > div.left-column > div.info-table-container > table:nth-child(1) {
  margin-right: 20px;
}

.mortgage-calculator-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 0px;
}

.mortgage-calculator {
  flex: 1;
  margin-right: 20px;
}

.mortgage-calculator .calculator-field {
  margin-bottom: 10px;
}

.mortgage-calculator .calculator-field label {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  display: block;
  margin-bottom: 5px;
  text-align:left;
}



.input-with-icon { display: flex; align-items: center;}

.input-with-icon span {
  display: inline-block;
  background-color: #d9d9d9;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
}

.input-with-icon input {
  flex: 1;
  padding: 9.75px;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0px;
}

.mortgage-calculator .calculator-field-inline {
  display: flex;
  justify-content: space-between;
}

.mortgage-calculator .calculator-field-inline .calculator-field {
  flex: 1;
  margin-right: 10px;
}

.mortgage-calculator .calculator-field-inline .calculator-field:last-child {
  margin-right: 0;
}

.mortgage-pie-chart {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px!important;
}

.mortgage-pie-chart canvas {
  max-width: 100%;
}

.mortgage-pie-chart .monthly-payment {
  margin-top: 20px;
  margin-bottom:20px;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
}

.mortgage-pie-chart .monthly-payment h4 {
  margin: 0;
  font-weight: bold;
}

.mortgage-calculator-container button {
  background-color: #9cdcf8;
  border: 1px solid black;
  border-radius: 0px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin-top: 10px;
}

.full-width-button {
  width: 100%;
}

.google-map {
  margin-top: 20px;
  width: 100%;
  height: 400px;
}

.google-map-container {
  position: relative;
  width: 100%;
  height: 400px;
}

.section-heading {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-weight:200;
  text-decoration: underline;
}

@media (min-width: 769px) {
  .info-table {
    width: 48%;
    margin-right: 2%;
  }
  .info-table:nth-child(even) {
    margin-right: 0;
  }

  .grid-template-features {
    padding-right:20px;
  }
}

@media (max-width:1650px) {
  .mortgage-calculator-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 5px;
  }

  .info-table-container {display:block;}


}

@media (max-width:769px) {

  .mortgage-calculator .calculator-field-inline {
    display:block;
  }
}

#root > div > div.listing-detail-container > div > div.main-content > div.left-column > p:nth-child(16) {text-align:left;}

#root > div > div.listing-detail-container > div > div.main-content > div.left-column > p:nth-child(18) {text-align:left;}

.listing-detail-content .address-heading {margin-bottom:0px;font-weight:400;}

.listing-detail-content .price {font-family:'Inter';font-weight:200;}

.listing-detail-content .details span {font-family:'Inter'; font-weight:200;}

/* New class for Listing History table */
.listing-history-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.listing-history-table th, .listing-history-table td {
  border: 0px solid #ccc;
  padding: 12px;
  text-align: left;
  font-family: 'Inter';
  font-size: 20px;
}

@media (max-width:1024px) {
  .listing-history-table th, .listing-history-table td {
    border: 0px solid #ccc;
    padding: 12px;
    text-align: left;
    font-family: 'Inter';
    font-size: 15px!important;
  }

  .listing-history-table th {
    font-size:16px!important;
  }

  .details .info-item {
    font-size:17px!important;
  }
}

.listing-history-table th {
  font-weight: bold;
  background-color: #f9f9f9;
  font-family: 'Inter';
  font-size: 20px;
}

.listing-history-table tr:nth-child(even) td {
  background-color: #f3fafd;
}

.listing-history-table td {
  background-color: #fff;
}

/* Style for blurred rows in Listing History table */
.blurred-row {
  filter: blur(5px);
  pointer-events: none; /* Disable interaction */
}

.listing-info {text-align:center!important;}

.listing-address {text-align:center!important;}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-top:20px;
  padding-bottom:20px;
  background-color: white;
  border-bottom:solid;
  border-bottom-width:1px!important;
  border-bottom-color:#D6D6D6;
  position: sticky;
  top: 0;
  z-index: 1000;
  
}

.banner-left,
.banner-right {
  display: flex;
  align-items: center;
}

.banner-middle {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.banner-middle .banner-menu-item {
  margin: 0 15px;
  font-size: 16px;
  color: #333;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
}

.banner-middle .banner-menu-item:hover {
  text-decoration: underline;
}

.banner-icon {
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

@media (max-width: 768px) {
  .banner-middle {
    display: none;
  }
}

/* Styling for the share link */
.banner-right .share-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-family:'Inter';
}

.banner-right .share-link:hover {
  text-decoration: underline;
}

.banner-right .banner-icon {
  margin-left: 5px; /* Adds one space between text and icon */
}

/* Wrapper for the fixed behavior */
.share-popup-wrapper {
  position: relative;
  z-index: 1001; /* Ensure it's above other elements */
}

/* Share Popup Styles */
.share-popup {
  position: fixed; /* Fixes the popup in a specific spot on the screen */
  top: 50%; /* Centers the popup vertically */
  left: 50%; /* Centers the popup horizontally */
  transform: translate(-50%, -50%); /* Ensures perfect centering */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 90%; /* Responsive width */
  z-index: 1001; /* Ensure it's above other elements */
}

.share-popup-content {
  text-align: center;
}

.share-popup-content h4 {
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #333;
}

.share-popup-content input.share-link-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f9f9f9;
}

.share-popup-content input.share-link-input:focus {
  outline: none;
  border-color: #9cdcf8;
}

.share-popup-content .copy-link-button {
  margin-top: 15px;
  background-color: #9cdcf8;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.share-popup-content .copy-link-button:hover {
  background-color: #7bb7e0;
}

.share-popup-content .copy-link-button:active {
  transform: scale(0.98);
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  color: #555;
  cursor: pointer;
}

.close-icon:hover {
  color: #000;
}

#root > div > div.listing-search-container > div.listing-popup > div > div > div.listing-detail-content > div.bottom-bar {display:none;}

/* Overlay to dim the background */
.share-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.google-reviews-section {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 0px;
  max-width:450px;
}

@media (max-width:1024px) {
  .google-reviews-section {
    padding:10px;
  }
}

.google-reviews-heading {
  font-size: 20px!important;
  color: #333!important;
  margin-bottom: 10px!important;
  text-align: center;
}

.review-card {
  padding: 15px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.star-rating {
  margin-bottom: 10px;
}

.star {
  font-size: 1.5rem;
}

.review-text {
  font-size: 17px!important;
  color: #000!important;
  margin-bottom: 10px!important;
}

.review-author {
  font-size: 0.875rem;
  color: #888;
}

@media (max-width:1250px) {
  .listing-search-container .listing-detail-container .main-content {display:block!important;}

  .google-reviews-section {max-width:none;width: 100%;}
}

.bottom-bar {display:none!important;}