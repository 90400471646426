/* src/components/StickyBottomBar.css */

/* Sticky Bottom Bar */
.sticky-bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #9cdcf8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    z-index: 1000; /* Ensures the bar stays on top */
  }
  
  /* Sticky Buttons */
  .sticky-button {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .sticky-button:hover {
    background-color: #8bcaf0; /* Slightly darker on hover */
  }
  
  .sticky-icon {
    margin-right: 8px;
    font-size: 18px;
    color: #000;
  }
  
  /* Modified Media Query to keep buttons side by side */
  @media (max-width: 600px) {
    .sticky-bottom-bar {
      flex-direction: row; /* Ensure row direction */
      padding: 10px 0; /* Adjust padding */
      flex-wrap: wrap; /* Allow wrapping if needed */
    }
  
    .sticky-button {
      margin: 5px 5px; /* Adjust margins */
      padding: 8px 16px; /* Smaller padding */
      font-size: 14px; /* Smaller font */
      flex: 1 1 45%; /* Each button takes up ~45% of the width */
      justify-content: center;
    }
  
    .sticky-icon {
      margin-right: 6px; /* Reduce margin */
      font-size: 16px; /* Slightly smaller icon */
    }
  }
  
  /* New Media Query for Screens 450px and Smaller */
  @media (max-width: 450px) {
    .button-text {
      display: none; /* Hide the text */
    }
    
    .sticky-button {
      padding: 8px 12px; /* Adjust padding to accommodate icon-only */
      font-size: 0; /* Remove font size to eliminate any extra space */
    }
    
    .sticky-icon {
      margin-right: 0; /* Remove right margin as text is hidden */
      font-size: 20px; /* Optionally increase icon size for better visibility */
    }
  }
  
  /* Ensure the main content doesn't get hidden behind the sticky bar */
  body, .home-page, .buy-page { /* Adjust selectors based on your layout */
    padding-bottom: 60px; /* Adjust based on the height of the sticky bar */
  }
