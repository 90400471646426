/* src/components/About.css */

.about-page {
  width: 100%;
  overflow-x: hidden;
  background-color: #F3F3F3;
}

.about-page .google-reviews {
  background-color: white;
}

.about-page .custom-city-container {
  background-color: #F3F3F3;
}

.about-page .widget-container-city {
  background-color: #F3F3F3;
}

.about-page .content-image-section {
  max-width: 1500px;
  background-color: white;
  border-radius: 5px;
  padding: 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 30px;
}

.about-page .text-content {
  flex: 1;
  padding: 20px;
}

.about-page .image-content {
  flex: 1;
}

.about-page .about-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  object-fit: cover;
}

.about-page .full-width-text-section {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  padding: 0rem 0rem;
  background-color: white !important;
}

.about-page .full-width-text-section h2 {
  text-align: left;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* Counter Section Styles */
.about-page .counter-section {
  max-width: 1500px;
  margin: 15px auto;
  padding: 15px;
  background-color: white;
}

.about-page .counter-section h2 {
  text-align: left;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top: 0px;
  padding-bottom: 15px;
}

.about-page .counter-row {
  display: flex;
  margin-bottom: 0px;
  gap: 20px;
}

/* Updated Counter Box Styles */
.about-page .counter-box {
  text-align: center;
  padding: 20px;
  background-color: #F3F3F3; /* Changed to match overall background */
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
  position: relative;
  overflow: hidden;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.about-page .counter-box.expanded {
  min-height: 180px;
  border: 2px solid #1b478c; /* Highlight when expanded */
}

.about-page .counter-content {
  position: relative;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-page .counter-content.hidden {
  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;
}

.about-page .counter-details {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 20px;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-page .counter-details.visible {
  transform: translateY(0);
  opacity: 1;
  background-color: #F3F3F3;
}

.about-page .counter-details p {
  font-size: 0.9rem;
  line-height: 1.4;
  color: #333;
  margin: 0;
  text-align: center;
}

.about-page .counter-box img {
  width: 60px;
  height: 60px;
  loading: lazy;
}

.about-page .counter-box h3 {
  font-size: 1.8rem;
  margin: 8px 0;
  color: #1b478c;
}

.about-page .counter-box p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 10px;
}

.about-page .read-more-btn {
  background: none;
  border: 2px solid #1b478c;
  color: #1b478c;
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;
}

.about-page .read-more-btn:hover {
  background-color: #1b478c;
  color: white;
}

.about-page .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #1b478c;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.about-page .overall-rating {
  background-color: #F3F3F3;
  margin-bottom: 0px;
  padding-bottom: 15px;
}

.about-page .slider-container {
  background-color: #F3F3F3;
  margin-top: 0px !important;
  padding-top: 15px;
}

.about-page .close-btn:hover {
  background-color: #1b478c;
  color: white;
}

/* Google Reviews Section */
.about-page .google-reviews-section {
  padding: 40px;
  padding-bottom: 0px;
  background-color: #F3F3F3;
  margin-top: 15px;
  max-width: 1500px;
  margin: auto;
}

.about-page .google-reviews-section h2 {
  text-align: center;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top: 0px;
  padding-bottom: 15px;
  margin-bottom: 0px;
}

/* Toronto Star Section */
.about-page .toronto-star-wrapper {
  display: flex;
  justify-content: center;
  margin: 15px auto;
  max-width: 1500px;
  background-color: white;
}

.about-page .toronto-star-container {
  max-width: 1500px;
  background-color: white;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 15px;
}

.about-page .home-page-toronto-star {
  flex: 1;
}

.about-page .toronto-star-image-column {
  flex: 0.4;
  padding-left: 1rem;
}

.about-page .thestar-office-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.about-page .city-links-section {
  margin: 15px auto;
  max-width: 1500px;
}

.about-page .read-more-link {
  display: inline-block;
  margin-top: 15px;
  color: #1b478c;
  text-decoration: none;
  font-weight: 500;
}

.about-page .read-more-link:hover {
  text-decoration: underline;
}

/* Additional Text Section */
.about-page .additional-text-section {
  max-width: 1500px;
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  text-align:left;
}

.about-page .additional-box {
  margin-bottom: 20px;
}

.about-page .additional-box h3 {
  font-size: 1.8rem;
  color: #1b478c;
  margin-bottom: 10px;
}

.about-page .additional-box p {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.6;
}

.about-page .additional-paragraph {
  text-align: left;
  font-size: 1.1rem; /* Updated to match .additional-box p */
  color: #333;
  line-height: 1.6; /* Added to match .additional-box p */
}

/* Smooth Scrolling Behavior */
html {
  scroll-behavior: smooth;
}

/* Responsive Styles */
@media (max-width: 1550px) {
  .about-page .full-width-text-section {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .about-page .content-image-section {
    flex-direction: column;
    padding: 15px;
  }

  .about-page .text-content {
    padding: 15px;
  }

  .about-page .text-content h2 {
    font-size: 2rem;
    text-align: center;
  }

  .about-page .text-content p {
    font-size: 1rem;
    text-align: center;
    line-height: 2rem;
  }

  .about-page .counter-row {
    flex-direction: column;
    align-items: center;
  }

  .about-page .counter-box {
    width: 90%;
    margin-bottom: 20px;
  }

  .about-page .counter-box.expanded {
    min-height: 150px;
    border: 2px solid #1b478c; /* Ensure border remains on smaller screens */
  }

  .about-page .counter-details p {
    font-size: 0.85rem;
  }

  .about-page .toronto-star-container {
    flex-direction: column;
  }

  .about-page .toronto-star-image-column {
    padding-left: 0;
    margin-top: 1rem;
  }

  .about-page .full-width-text-section h2 {
    font-size: 2rem;
  }

  /* Additional Text Section Responsive */
  .about-page .additional-text-section {
    padding: 15px;
  }

  .about-page .additional-box h3 {
    font-size: 1.5rem;
  }

  .about-page .additional-box p {
    font-size: 1rem;
  }

  .about-page .additional-paragraph {
    font-size: 1rem;
  }
}
