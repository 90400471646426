/* General Styles */
.home-page {
  font-family: 'Inter', sans-serif;
}

@media (min-width:768px) {
#root > div > div > div.fullwidth-container > div > div.icon-boxes-container > div.icon-box.fade-in.phone-icon.visible {display:none!important;}
}

@media (max-width:768px) {
  .blurb-numbers {
    font-size:13px;color:#3B6AA7;margin-top:0px;margin-bottom:0px;min-height:50px;
  }

  .blurb-text {font-size:10px;color:black;min-height:120px;margin-top:0px;margin-bottom:0px;}

  .icon-box-mobile p {margin-top:0px;margin-bottom:0px;min-height:25px;}

  .icon-box-mobile h3 {margin-top:0px;margin-bottom:0px;min-height:20px;}
}


.featured-listings-heading {
  font-family: 'Inter'!important;
    font-size: 2.5rem!important;
    font-weight: 700;
    padding-top:30px;
    padding-bottom:15px;
    margin-top: 0px;
    color: #000;
    background-color:#F3F3F3;
}

/* Responsive adjustments for mobile */
@media (max-width: 1050px) {
  /* Ensure the icon and text inside blue boxes are properly aligned */
  .home-page .home-page-blue-icon-square {
    margin-right: 0; /* Remove margin that pushes the box off-center */
    margin-left: auto; /* Center the blue box horizontally */
    margin-right: auto; /* Center the blue box horizontally */
  }

  /* Make sure the entire column is centered on mobile */
  .home-page .home-page-icon-text-container {
    justify-content: center; /* Center the container */
  }
}

/* Fullwidth Container */
.home-page .fullwidth-container {
  background-color: #F3F3F3;
  width: 100%;
}

.home-page .video-container {
  padding: 0px;
}

@media (max-width:1050px) {
  /* Video and Icon Boxes Section */
.home-page .video-and-icons-section {
  display: block!important;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  max-width: 1500px;
  margin: auto;
  
  background-color: #F3F3F3;
}
}

/* Video and Icon Boxes Section */
.home-page .video-and-icons-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  max-width: 1530px;
  margin: auto;
  padding-left:15px;
  padding-right:15px;
  padding-bottom:15px;
  background-color: #F3F3F3;
  padding-bottom:0px;
  align-items:stretch;
}

.video-container, .icon-boxes-container {
  flex: 1;
}

.video-container {
  position: relative;
  max-width: 100%; /* Keep video scaling within the grid */
  padding-bottom:15px!important;
}

.video-and-icons-section .video-container video {
  width: 100%;
  border-radius: 5px;
}

.video-and-icons-section .unique-play-icon  {width:40px!important;height:40px!important;}

.video-and-icons-section .unique-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  cursor: pointer;
  background-color: #fff;
  padding: 20px;
  border-radius: 99%;
  transform: translate(-50%, -50%);
  color:#000;
  border:3px solid black;
  width:88px;
  
}

/* Pulsating rings */
.video-and-icons-section .unique-play-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120%;
  height: 120%;
  background-color: rgba(156, 220, 248, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  animation: pulsate-ring 2s infinite ease-out;
  z-index: -1;
}

@keyframes pulsate-ring {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}





@media (max-width: 768px) {
  /* Stacks columns vertically for mobile screens */
  .video-and-icons-section {
    flex-direction: column;
  }
  
  .video-container,
  .icon-boxes-container {
    max-width: 100%;
    transform: scale(1); /* Reset scaling */
    min-width: auto; /* Allow full width on smaller screens */
  }
}

.icon-boxes-container {
  flex: 0 0 15%; /* 15% width for the icons */
  max-width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
}



@media (max-width: 768px) {

  .mobile-icon-container {display:none!important;}

  /* Stack columns vertically on mobile */
  .video-and-icons-section {
    flex-direction: column;
  }
  
  .video-container,
  .icon-boxes-container {
    max-width: 100%;
    transform: scale(1); /* Reset scale */
  }
}

@media (max-width:900px) {
  /* Icon Boxes in Video and Icons Section */
.video-and-icons-section .icon-boxes-container {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
}
}

@media (max-width:768px) {
  /* Icon Boxes in Video and Icons Section */
.video-and-icons-section .icon-boxes-container {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0rem!important;
}
}

@media (min-width:1550px) { .video-container {
  min-height:717px!important;
}

@media (max-width:1550px) {
  .video-and-icons-section .icon-box h3 {
    font-size: 1.078vw!important;
    font-weight: 500;
    font-family: 'Inter';
    color: #000;
}

}

}
.video-and-icons-section .icon-box {
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
}

.home-page .icon-box-below h3 {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.home-page .google-icon-title-container {
  margin-left: auto;
  margin-right: auto;
}

.video-and-icons-section .icon-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.video-and-icons-section .icon-title-container.side-by-side {
  flex-direction: row;
  justify-content: start;
}

@media (max-width:1250px) {
  .video-and-icons-section .icon-title-container.side-by-side {
    flex-direction: row;
    justify-content: center;
  }
}

.icon-title-container h3 {
  text-align: left;
}

.contact-icon {height:1.3em;width:1.3em;margin-bottom:0px;}

/* Add new styles for blue circle icons */
.home-page .contact-icon-container {
  background-color: transparent; /* Change background color of the icon box to white */
  border-radius: 5px; /* Make the icon container round */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-page .contact-icon {
  font-size: 30px;
  color: #000; /* Icon color */
}

.video-and-icons-section .icon-box h3 {
  font-size: 1.15rem;
  font-weight: 500;
  font-family: 'Inter';
  color: #000;
}

.home-page .video-and-icons-section .icon-box:hover {
  background-color: #d5ecf5;
}

.stars-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.star-icon {
  color: #ffb400;
  font-size: 24px;
  margin: 0 2px;
}

.google-logo {
  width: 80px;
  height: auto;
}

/* Responsive Styles */
@media (max-width: 1250px) {
  .home-page .video-container {
    width: 100% !important;
  }
  
  .home-page .video-and-icons-section .icon-boxes-container {
    width: 20% !important;
  }
  
  .home-page .google-logo {
    content: url('../assets/images/google-G.png') !important;
    width: 25px !important;
  }
}

@media (max-width: 1050px) {
  .home-page .video-and-icons-section {
    display: flex !important;
    flex-direction: row !important;
    padding-bottom:0px;
  }

  .video-container {
    width: 75% !important;
  }

  .video-and-icons-section .icon-boxes-container {
    width: 25% !important;
    flex-direction: column !important;
    
  }

  .icon-boxes-container .icon-box {
    width: 100% !important;
    margin-bottom: 15px !important;
    padding:5px!important;
    height:100%;

  }

  .phone-email-icon {
    width: 25px !important;
    height: 25px !important;
  }

  .contact-icon {
    font-size: 1.1em !important;
  }

  /* Keep all three boxes visible */
  .icon-boxes-container .icon-box:nth-child(3) {
    display: flex !important;
  }

  /* Hide fullwidth Google reviews */
  .google-reviews-fullwidth {
    display: none !important;
  }
}

@media (max-width: 900px) {
  .star-icon {
    font-size: 2vw !important;
  }}

@media (max-width: 768px) {
  .home-page .video-container {
    max-width: 100% !important;
    padding-right:15px;
    height:100%;
  }

  .home-page .google-logo {
    margin-top:5px;
    
  }

  .video-and-icons-section .icon-boxes-container {
    max-width: 20% !important;
  }

  .phone-email-icon {
    width: 60px !important;
    height: 40px !important;
  }

  .contact-icon {
    font-size: 1.5em !important;
  }

  .star-icon {
    font-size: 2vw !important;
  }

  .google-logo {
    width: 30px !important;
  }

  .home-page .video-container {
    padding:0px!important;
    padding-right:15px!important;
    padding-bottom:11px!important;
  }
}

@media (max-width:500px) {
  .phone-email-icon {
    width: 40px !important;
    height: 40px !important;
  }

  .contact-icon {
    font-size: 4vw !important;
    height: 3vw !important;
    width: 3vw !important;
  }

  .home-page .google-logo {
    width: 20px !important;
  }

  .icon-boxes-container .icon-box {
    padding:4px!important;
  }
}
#root > div > div > div.fullwidth-container > div > div.icon-boxes-container > div:nth-child(1) {
  background-color: #9CDCF8; /* Set background color to blue */
}

#root > div > div > div.fullwidth-container > div > div.icon-boxes-container > div:nth-child(2) {background-color: #9CDCF8; /* Set background color to blue */
}


/* Styling for the phone and email icon boxes */
.phone-email-icon {
  background-color: #9CDCF8; /* Set background color to blue */
  border-radius: 5px; /* Set 5px border radius */
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; /* Center the icon */
}



/* Adjust the icon container to center within the box */
.icon-title-container {
  display: flex;
  justify-content: center; /* Center icons within the box */
  align-items: center;
}

.google-reviews-fullwidth {display:none;}

@media (max-width:480px) {

}



/* Media query for mobile (max-width 768px) */
@media (max-width: 1050px) {
  .icon-boxes-container .icon-box:nth-child(3) {
    display: none; /* Hides only the Google reviews column */
  }
}

@media (max-width: 900px) {
  /* Hide the text below phone and email icons */
  .icon-boxes-container .icon-box h3.icon-text-google {
    display: none;
  }
}


#root > div > div > div.home-page-toronto-star.fade-in.visible > img {width:400px;}

/* Icon Boxes Below Section */
.icon-boxes-below {
  width: 100%;
  padding: 0rem 0;
  background-color: #F3F3F3;
}

.home-page-toronto-star {max-width:1500px;margin-left:auto;margin-right:auto;text-align:left;}

/* Default layout: 6 columns in a row for desktop */
.icon-boxes-row {
  display: block;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 0rem;
  
}

/* Adjust to fit 6 columns on desktop */
.icon-box-below {
  flex: 1 1 calc(16.66% - 1rem); /* 6 columns */
  margin: 1rem 0;
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  margin:0px;
  margin-bottom:15px;
}



/* Icon inside the box */
.icon-box-below .counter-icon {
  width: 50px;
  height: 50px;
  margin: 10px;
}

.under-steps .read-more-link {
  background: #9CDCF8;
  color: black;
  border: none;
  font-size: 1rem;
  padding: 15px 15px;
  cursor: pointer;
  font-family: 'Inter';
  font-weight: 500;
  margin-top: 30px !important;
  border-radius: 5px;
  display: table;
  margin-top: 30px!important;
  margin-bottom:30px;
}

.read-more-link {
  background: #9CDCF8;
  color: black;
  border: none;
  font-size: 1rem;
  padding: 15px 15px;
  cursor: pointer;
  font-family:'Inter';
  font-weight:500;
  margin-top:30px!important;
  border-radius:5px;
  display: table;
  margin-top: 15px;
}

/* Smaller text size for each box */
.icon-box-below h3 {
  font-size: 18px; /* Adjusted smaller font size */
  font-weight: 500;
  color: black;
}

/* Responsive layout for screens between 1450px and 1200px: 3 columns */
@media (max-width: 1450px) {
  .icon-boxes-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 3 columns */
    grid-gap: 15px;
    padding-bottom:15px;
    



  }


  .icon-box-below {
    width: 100%; /* Full width inside its column */
    margin:0px;
    
  }
}

/* Responsive adjustments for smaller screens: 3 columns */
@media (max-width: 1200px) {
  

  .icon-box-below {
    width: 100%; /* Ensure full width of column */
  }
}

/* Mobile adjustments: 2 columns on mobile */
@media (max-width: 768px) {
  .icon-boxes-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 2 columns on mobile */
    justify-items: center;
  }

  .icon-box-below {
    flex: none;
    width: 100%;
    margin: 0;
    padding: 15px;
  }

  .icon-boxes-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-gap: 15px; /* Gap between columns */
    padding:0px;
  }

}

/* Add this new media query for the counter icons */
@media (max-width: 600px) {
  .icon-box-below .counter-icon {
    display: none !important;
  }

  /* Adjust the text styling now that icons are hidden */
  .icon-box-below h3 {
    margin: 0 !important;
    padding: 10px !important;
    font-size: 0.6rem !important;  /* Slightly smaller font for better mobile fit */
    text-align: center !important;
  }

  .icon-box-below {
    padding: 10px !important;  /* Reduce padding since we don't have icons */
    justify-content: center !important;
  }

  .icon-boxes-row {
    grid-gap: 10px !important;  /* Slightly tighter gap between boxes */
    
  }
}

.home-page-column  {
  margin:10px;
}


/* Blue Section */
.home-page .home-page-blue-section {
  display: flex;
  justify-content: normal;
  padding: 15px 0rem;
  background-color: #fff;
  color: white;
  text-align: center;
  flex-wrap: nowrap;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width:1050px) {
  .home-page .home-page-blue-section .home-page-column {
    flex: 1;
    padding: 20px;
    margin:7.5px!important;
    background-color: #f3f3f3;
    border-radius: 5px;
    min-width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: end;

    display:block!important;
  }
}

.home-page .home-page-blue-section .home-page-column {
  flex: 1;
  padding: 20px;
  margin:7.5px!important;
  background-color: #f3f3f3;
  border-radius: 5px;
  min-width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: end;
  display:grid;
}



.home-page .home-page-icon-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.home-page .home-page-icon-text-container h2 {
  margin-left: 1rem;
}

.home-page .home-page-blue-icon-square {
  background-color: #fff;
  width:75px;
  height:75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left:auto!important;
  margin-right:auto!important;
  margin:auto;
  
  transition: background-color 0.3s ease;
}

.home-page .contact-form input {
  background-color: white;
}

/* Blue Section */
.home-page .home-page-blue-section .contact-form-column {
  margin-left: 1rem;
}

.home-page .checkbox-container {
  display: none;
}

.home-page .home-page-blue-icon-square .home-page-icon,
.home-page .home-page-blue-icon-square .home-page-icon i {
  width: 50px;
  height: 50px;
  font-size: 2rem;
  color: #3b6aa7;
  text-align: center;
  margin-top: 11px;
  transition: color 0.3s ease;
}

.home-page .home-page-blue-section h2 {
  font-family: 'Inter';
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  margin-top: 0px;
  margin-bottom:15px;
  margin-left:0px;
  
  align-content:center;
}

.home-page .contact-heading {
  font-family: 'Inter';
  font-size: 2rem!important;
  font-weight: 500!important;
  color: #000;
  margin-top: 0px!important;
  margin-bottom:15px;
  margin-left:0px;
  
  align-content:center;
}

@media (max-width:1465px) {
  .home-page .home-page-blue-section h2 {
    font-family: 'Inter';
    font-size: 1.7rem;;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
    margin-bottom:0px;
    
    align-content:middle;
    
  }
}

.home-page .home-page-blue-section p {
  font-size: 19px;
  font-family: 'Inter';
  color: #000;
  line-height: 2.5rem;
  margin-top:0px;
  margin-bottom:15px;
  
  align-content:start;
}


.toronto-star-wrapper {padding-bottom:40px;}

@media (max-width:1024px) {.toronto-star-wrapper {padding-bottom:0px;}}


.home-page .home-page-cta-button {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 21px;
  background-color: #9cdcf8;
  color: #000;
  border-radius: 5px;
  border: none;
  padding: 25px 35px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 0px;

}

.home-page .home-page-cta-button:hover {
  background-color: #86c2db;
}

.home-page .read-more-sublink {
  border-radius: 5px;
  background-color: #1a1a1a;
  font-size: 18px;
  margin-top: 10px;
  line-height: 40px;
  color:#fff;
}

/* Hover Effects */
.home-page .home-page-column:hover .home-page-blue-icon-square,
.home-page .home-page-blue-icon-square:hover {
  background-color: #9cdcf8;
}

.home-page .home-page-column:hover .home-page-icon,
.home-page .home-page-blue-icon-square:hover .home-page-icon {
  color: white;
}

/* Additional Responsive Adjustments */
@media (max-width: 1050px) {
  .home-page .home-page-blue-section {
    flex-direction: column;
  }

  .home-page .home-page-blue-section .home-page-column {
    margin:15px;
    min-width: 150px;
  }

  .home-page .home-page-blue-section h2 {
    font-size: 1.7rem;
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom:15px;
    align-content:center;
  }

  .icon-boxes-below {
    width:100%;
    padding:0px;
    padding-top:0px;

  }

  .home-page .home-page-blue-section p {
    font-size: 0.99rem;
    margin-top:0px;
    margin-bottom:15px;
    min-height:50px!important;
  }

  .home-page .home-page-cta-button {
    text-align: center;
    font-size: 17.25px;
  }

  .home-page .calculator-headings h2 {
    font-size: 2rem !important;
    line-height: 2.5rem;
  }

  .home-page .calculator-headings h3 {
    font-size: 1.5rem;
  }

  .home-page .home-google-reviews-section {
    margin-top: 2rem;
  }

  .home-page .icon-box-below {
    flex: none;
    width: 100%;
  }

  .home-page .home-page-lets-talk {
    background-position: center center;
  }
}

/* Fade-in Animation */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Existing styles ... */

/* Google Reviews Styling */
.video-and-icons-section .google-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toronto-star-heading {font-size:2.5rem;padding-top:15px;padding-bottom:15px;margin-top:0px;}

.toronto-star-paragraph {    font-size: 17px;
  font-family: 'Inter';
  color: #000;
  line-height: 2rem;
  margin-top: 0px;
  margin-bottom: 15px;
  align-content: start;}

@media (max-width:768px) {
  .toronto-star-paragraph {    font-size: 0.9rem;
    font-family: 'Inter';
    color: #000;
    line-height: 2rem;
    margin-top: 0px;
    margin-bottom: 15px;
    align-content: start;}

    .toronto-star-heading {font-size:1.5rem;padding-top:15px;padding-bottom:15px;margin-top:0px;}

    .featured-listings-heading {
      font-family: 'Inter';
      font-size: 2rem;
      font-weight: 700;
      padding-top: 30px;
      padding-bottom: 15px;
      margin-top: 0px;
      color: #000;
      background-color: #F3F3F3;
  }
  
}

@media (max-width:1170px) {
  .home-page .home-page-blue-section p {min-height:200px;}
}




.video-and-icons-section .stars-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.video-and-icons-section .star-icon {
  color: #ffb400;
  font-size: 24px;
  margin: 0 2px;
}

.video-and-icons-section .google-logo {
  width: 80px;
  height: auto;
}

.thestar-logo {width:400px;}

/* Toronto Star Container Styling */
.toronto-star-container {
  background-color: #F3F3F3;
  max-width: 1500px; /* Set max-width */
  margin: 15px; /* Center the container */
  display: flex;
  gap: 1rem;
  padding: 30px;
  align-items: center; /* Vertically center content */
  border-radius:5px;
}

/* Left Column in Toronto Star Section */
.home-page-toronto-star {
  flex: 1;
  text-align: left;
}

/* Right Column with Image */
.toronto-star-image-column {
  flex: 0.4;
  padding-left: 1rem;
}

.toronto-star-image-column img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}




/* Responsive adjustments for Google Reviews */
@media (max-width: 1300px) {
  .video-and-icons-section .star-icon {
    font-size: 20px;
  }

  .video-and-icons-section .google-logo {
    width: 70px;
  }

  .video-and-icons-section .icon-box h3 {
    font-size: 1rem;
  }
}

@media (max-width: 1100px) {
  .video-and-icons-section .star-icon {
    font-size: 18px;
  }

  .video-and-icons-section .google-logo {
    width: 60px;
  }

  .video-and-icons-section .icon-box h3 {
    font-size: 0.9rem;
  }
}

@media (max-width: 900px) {
  .video-and-icons-section .star-icon {
    font-size: 25px;
  }

  .video-and-icons-section .google-logo {
    width: 150px;
  }

  .video-and-icons-section .icon-box h3 {
    font-size: 0.8rem;
  }
}

/* Existing mobile styles ... */

/* Rest of the existing styles ... */

/* End of Home.css */

.toronto-star-container {background-color:#F3F3F3;}

@media (max-width:768px) {
  .toronto-star-image-column {display:none!important;}
  .toronto-star-container {padding:15px;}
  .toronto-star-wrapper {display:block;}
  .thestar-logo {width:300px;}
}

@media (max-width:1550px) {
  
  
  .video-and-icons-section .icon-box h3 {
    font-size: 1.1vw!important;
    font-weight: 500;
    font-family: 'Inter';
    color: #000;
}



.icon-boxes-container {
  flex: 0 0 20%;
  max-width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;}

.icon-box-below {padding:20px;}

.icon-box-below h3 {
  font-size: 1.1vw!important;
  font-weight: 500;
  color: black;
}

.contact-icon {
  height: 2vw!important;
  width: 2vw!important;
  margin-bottom: 0px;
}

}

.blurb-text-desktop {
  font-weight:600;
  color:#3B6AA7;
}


@media (max-width:768px) {
  .contact-icon {
    height: 4vw!important;
    width: 4vw!important;
    margin-bottom: 0px;
  }
}

/* Begin scaling down the icon box container starting at 1550px */
@media (max-width: 1550px) {
  .icon-boxes-container {
    max-width: 25%; /* Icon boxes shrink to 28% */
  }
}

@media (max-width: 1450px) {
  .icon-boxes-container {
    max-width: 25%; /* Icon boxes shrink to 25% */
  }
}

@media (max-width: 1350px) {
  .icon-boxes-container {
    max-width: 17%; /* Icon boxes shrink to 22% */
  }
  
  .icon-text-google {display:none;}
}

@media (max-width: 1250px) {
  .icon-boxes-container {
    max-width: 17%; /* Icon boxes shrink to 20% */
  }
}

@media (max-width: 1150px) {
  .icon-boxes-container {
    max-width: 18%; /* Icon boxes shrink to 18% */
  }
}

@media (max-width: 1050px) {
  .icon-boxes-container {
    max-width: 20%; /* Icon boxes shrink to 12% */
    flex: 0 0 20%;
    max-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1rem;
  }
}

@media (max-width: 1000px) {
  .icon-boxes-container {
    max-width: 25%; /* Icon boxes shrink to 12% */
    flex: 0 0 25%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1rem;
  }

  .google-logo {margin-top:0px!important;}

  .icon-box-below {padding:10px;}

  .icon-box-below h3 {font-size:1.2vw!important;}
}

@media (max-width: 850px) {
  .icon-boxes-container {
    max-width: 30%; /* Icon boxes shrink to 12% */
    flex: 0 0 30%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1rem!important;
    padding-right:15px;
  }

  .video-and-icons-section {padding-left:15px!important;padding-right:0px!important;align-items:stretch;}
}

@media (max-width: 768px) {
  /* Stack vertically on smaller screens */
  .video-and-icons-section {
    flex-direction: column;
    align-items:stretch!important;
    margin-top:0px!important;
  }

  .video-container,
  .icon-boxes-container {
    max-width: 100%; /* Full width for each on mobile */
  }

  .icon-boxes-container {
    
  }
}


@media (max-width:768px) {
  .icon-boxes-below {display:none;}

  .mobile-icon-container {display:flex!important;}

}

.icon-text-google {font-size:15px!important;}


@media (max-width:768px) { #root > div > div > div.fullwidth-container > div.video-and-icons-section.fade-in.visible > div.icon-boxes-container > div:nth-child(4) {display:none;}}

@media (max-width:768px) { #root > div > div > div.fullwidth-container > div.video-and-icons-section.fade-in.visible > div.icon-boxes-container > div:nth-child(5) {display:none;}}

@media (max-width:768px) { #root > div > div > div.fullwidth-container > div.video-and-icons-section.fade-in.visible > div.icon-boxes-container > div:nth-child(6) {display:none;}}


.mobile-icon-container {display:none;gap:15px;max-width:1500px;align-items: center;padding-top:0px;margin-left:auto;margin-right:auto;padding-bottom:20px;}

.google-icon-title-container-mobile {background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content:center;
  height:100%;
  padding-top: 30px!important;
  padding-bottom:30px!important;
  

}

.icon-box-mobile {font-family:'Inter';font-size:10px;width: 100%;}

@media (max-width:568px) {

  .mobile-icon-container {display:flex;gap:15px;max-width:1500px;padding: 15px;align-items: center;padding-top:0px;}

.google-icon-title-container-mobile {background-color: white;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content:center;
  height:100%;
  
  

}

.icon-box-mobile {font-family:'Inter';font-size:9px;width: 100%;}

}

@media (max-width: 768px) {
  .star-icon {
      font-size: 1.5vw !important;
  }}

  .phone-text {font-weight:500;font-family:'Inter';margin-bottom:0px;color:black;}

  @media (max-width: 1050px) {
    .phone-text {font-weight:500;font-family:'Inter';margin-bottom:0px;font-size:1.1vw;}
  }

  @media (max-width: 768px) {
    .phone-text {font-weight:500;font-family:'Inter';margin-bottom:0px;font-size:1.6vw;display:none;}

    .google-icon-title-container-mobile {background-color: white;
      border-radius: 5px;
      padding: 5px;
      display: block;
      align-items: center;
      text-align: center;
      vertical-align: top;
      justify-content:center;
      height:100%;
      
      vertical-align: middle;
      
    
    }

    .home-page .home-page-blue-section .home-page-column {
      flex: 1 1;
      padding: 20px;
      margin: 15px !important;
      margin-top:0px!important;
      margin-bottom:15px!important;
      background-color: #f3f3f3;
      border-radius: 5px;
      min-width: 200px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;


  }
  }

  @media (max-width:1470px) {
    .home-page .home-page-blue-section p {min-height:160px;}
  }

  @media (max-width:1170px) {
    .home-page .home-page-blue-section p {min-height:200px;}
  }


/* Sticky Bottom Bar */
.sticky-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #9cdcf8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  z-index: 1000; /* Ensures the bar stays on top */

}

.sticky-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border-radius:5px;
}

.sticky-button:hover {
  background-color: #8bcaf0; /* Slightly darker on hover */
}

.sticky-icon {
  margin-right: 8px;
  font-size: 18px;
  color: #000;
}

/* Responsive Design */
@media (max-width: 600px) {
  .sticky-bottom-bar {
    flex-direction: column;
    padding: 15px 0;
  }

  .sticky-button {
    margin: 5px 0;
    width: 90%;
    justify-content: center;
  }

  .sticky-icon {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

/* Ensure the main content doesn't get hidden behind the sticky bar */
.home-page {
  padding-bottom: 60px; /* Adjust based on the height of the sticky bar */
}
